import React, { useEffect, useState } from 'react'
import { useWindowSizes } from '../hooks/custom'

const TvRatioContext = React.createContext({ ratio: 0, safeRatio: false })

const TvRatio = ({children, ratioMax = 2, ratioMin = 1.3}) => {
    const [tvFrameLoaded, setTvFrameLoaded] = useState(false)
    const windowSizes = useWindowSizes()
    console.log(windowSizes.ratio)
    const safeRatio = windowSizes.ratio > ratioMin && windowSizes.deviceOrientation === 'landscape'
    const tvFrameUrl = `images/tv-frame${safeRatio ? '' : '-no-controls'}.png`
    
    useEffect(() => {
        const tvFrame = document.createElement('IMG')
        const handleImageLoad = () => {
            setTvFrameLoaded(true)
        }

        tvFrame.addEventListener('load', handleImageLoad);
        tvFrame.src = tvFrameUrl

        return () => tvFrame.removeEventListener('load', handleImageLoad);
    }, [])

    return (
        <TvRatioContext.Provider value={{ 
            ratio: windowSizes.ratio,
            safeRatio,
            tvFrameUrl,
            tvFrameLoaded
             }}>
            {children}
        </TvRatioContext.Provider>
    )
}

export default TvRatio
export {
    TvRatioContext
}

