import styled from 'styled-components/macro'
import {stylesOverlay} from "../../styles"

const GlitchedLink = styled.a`
position: relative;
display: inline-block;
font-size: inherit;
font-weight: 700;
color: yellow;
text-decoration: underline;


&:hover,
&:focus,
&:active {
    color: transparent;
&::before,
&::after  {
        opacity: 1;
    }
}

&::before,
&::after {
    content: attr(data-title);
    ${stylesOverlay}
    color: yellow;
    opacity: 0;
    text-decoration: underline;
}

&::before {
    transform: translateX(-5px);
    text-shadow: -1px 0 #fe0067;
    clip-path: inset(0 0 50% 0);
}

&::after {
transform: translateX(5px);
    text-shadow: -1px 0 #55feff;
    clip-path: inset(50% 0 0 0);
}
`

export default GlitchedLink