import React, { useEffect } from 'react';
import styled from 'styled-components/macro'
import { useSpring, animated, config } from 'react-spring'

const Pointer = styled(animated.div)`
position: fixed;
top: 0;
left: 0;
width: 8rem;
height: 8rem;
margin-left: -4rem;
margin-top: -4rem;
background-color: #fe0067;
border-radius: 50%;
pointer-events: none;
transition: background-color .25s;
z-index: 99;
`

const mouse = {
    position: {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
    },
    lastPosition: {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
    },
    delta: 0,
    friction: 30,
    scale: .1,
    timeout: undefined
}

const MousePointer = () => {
    const [spring, set] = useSpring(() => ({
        to: {
            backgroundColor: '#fe0067',
            transform: `translate(${mouse.position.x}px, ${mouse.position.y}px) scale(0.1)`,
            opacity: 1,
        },
        config: name => {
            if (name == 'transform') {
                return config.wobbly
            }

            return {
                tension: 500
            }
        }
    }))

    const handleMovement = e => {        
        mouse.lastPosition = mouse.position
        mouse.position = {
            x: e.clientX,
            y: e.clientY
        }

        set({ transform: `translate(${mouse.position.x}px, ${mouse.position.y}px) scale(${Math.max(.3, 1 - mouse.delta) * mouse.scale})` })

        // calculate the mouse move strength
        if (mouse.lastPosition.x && mouse.lastPosition.y) {
            let delta = Math.sqrt(Math.pow(mouse.position.x - mouse.lastPosition.x, 2) + Math.pow(mouse.position.y - mouse.lastPosition.y, 2)) / mouse.friction;
            delta = Math.min(4, delta);

            mouse.delta = delta
        }
    }

    const handleIn = e => {
        mouse.scale = 1
        set({
            backgroundColor: 'yellow',
            opacity: .15
        })
    }

    const handleOut = e => {
        mouse.scale = .1
        set({
            backgroundColor: '#fe0067',
            opacity: 1
        })
    }

    useEffect(() => {

        const links = Array.from(document.querySelectorAll('a, button'))

        window.addEventListener("mousemove", handleMovement)
        
        links.map(a => {
            a.addEventListener("mouseover", handleIn)
            a.addEventListener("mouseout", handleOut)
        })

        return () => {
            window.removeEventListener("mousemove", handleMovement)

            links.map(a => {
                a.removeEventListener('mouseover', handleIn)
                a.removeEventListener('mouseout', handleOut)
            })
        }
    }, [])

    return <Pointer style={spring} />
};

export default MousePointer;
