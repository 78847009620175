import { useEffect, useState } from "react";
import {
    debounce,
    throttle as performanceThrottle,
    lerp
} from '../helpers'

function useWindowSizes() {
    const calcSizes = () => {
        let sizes = { w: 0, h: 0, ratio: 0, deviceOrientation: 'landscape' }
        if (window) {
            sizes = {
                w: window.innerWidth,
                h: window.innerHeight,
                ratio: window.innerWidth / window.innerHeight,
                deviceOrientation: window.innerWidth >= window.innerHeight ? 'landscape' : 'portrait'
            }
        }

        return sizes
    }

    const [sizes, setSizes]: any = useState(calcSizes());
    const getWindowSizes = e => debounce(requestAnimationFrame(() => setSizes(calcSizes())), 150)

    useEffect(() => {
        window.addEventListener('resize', getWindowSizes);

        return () => window.removeEventListener('resize', getWindowSizes);
    }, []);

    return sizes;
}

function useScroll(options) {
    const { dampen, throttle } = options ? options : {}
    const calcScroll = () => window.pageYOffset || document.documentElement.scrollTop
    const [scroll, setScroll]: any = useState(calcScroll)

    useEffect(() => {

        const updateScroll = e => {
            const newScrollOffset: number = dampen ? lerp(scroll, calcScroll(), dampen) : calcScroll()
            requestAnimationFrame(() => setScroll(newScrollOffset))
        }

        const handleScroll = throttle ? performanceThrottle(updateScroll, throttle) : updateScroll

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return scroll;
}

// TO IMPLEMENT!!!
/* function useUserAgent({mobileBreakpoint = 769 }) {

    useEffect(() => {

    }, [])
        const smallWindow = window.innerWidth < mobileBreakpoint
        const mobileUserAgent = (/Android|iPhone|iPad|iPod|BlackBerry/i).test(navigator.userAgent || navigator.vendor || window.opera)
        const retinaCheck = ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)').matches)) || (window.devicePixelRatio && window.devicePixelRatio > 1.3))
        const envCheck = ((/localhost/i).test(window.location) ? 'dev' : 'prod')
      
        const mobileCheck = (smallWindow || mobileUserAgent)
      
        return {
          isMobile: mobileCheck,
          isRetina: retinaCheck,
          env: envCheck,
        }
} */

export {
    useWindowSizes,
    useScroll
}