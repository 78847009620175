function debounce(func:Function , wait:number, immediate:boolean = false) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

/**
 * Returns a function, that, when invoked, will only be triggered at most once
 * during a given window of time. Normally, the throttled function will run
 * as much as it can, without ever going more than once per `wait` duration;
 * but if you'd like to disable the execution on the leading edge, pass
 *`{leading: false}`. To disable execution on the trailing edge, ditto.
 * @param func
 * @param wait
 * @param options
 * @returns {function(): *}
 */
function throttle(func, wait, options) {
    var context, args, result
    var timeout = null
    var previous = 0
    if (!options) options = {}
    var later = function () {
      previous = options.leading === false ? 0 : Date.now()
      timeout = null
      result = func.apply(context, args)
      if (!timeout) context = args = null
    }
    return function () {
      var now = Date.now()
      if (!previous && options.leading === false) previous = now
      var remaining = wait - (now - previous)
      context = this
      args = arguments
      if (remaining <= 0 || remaining > wait) {
        if (timeout) {
          clearTimeout(timeout)
          timeout = null
        }
        previous = now
        result = func.apply(context, args)
        if (!timeout) context = args = null
      } else if (!timeout && options.trailing !== false) {
        timeout = setTimeout(later, remaining)
      }
      return result
    }
  }

// map number x from range [a, b] to [c, d]
const mapNum = (x:number, a:number, b:number, c:number, d:number) => (x - a) * (d - c) / (b - a) + c


/**
 * Linear interpolation
 * @param a eg previous scroll position
 * @param b eg current scroll position
 * @param n ease/damping
 * @returns {number}
 */
const lerp = (a:number, b:number, n:number) => (1 - n) * a + n * b

const scaleTo: Function = (elW:number, elH:number, boxW:number, boxH:number, scaleType:string = 'fit') => {
    const scaleFunc = {
        fit: 'min',
        fill: 'max'
    }

    if (!scaleFunc[scaleType]) {
        console.error('Cannot calculate scale!(Wrong scaleType param)')
        return
    }

    // get the scale
    const scale = Math[scaleFunc[scaleType]](boxW / elW, boxH / elH);

    return {     // get the top left position of the image
        scale: scale,
        x: (boxW / 2) - (elW / 2) * scale,
        y: (boxH / 2) - (elH / 2) * scale
    }
}

const shuffle = array => array.sort(() => .5 - Math.random())

export {
    debounce,
    throttle,
    mapNum,
    lerp,
    scaleTo,
    shuffle,
    
}