import React from 'react';
import styled from 'styled-components/macro'
import {useSpring} from "react-spring";

const SVG = styled.svg`
max-width: 100%;
height: 100%;
`
const Chevron = ({fill =  '#fe0067', animationStyles}) => {
    return (
        <div>
            <SVG width="364px" height="215px" viewBox="0 0 364 215" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Welcome" stroke="none" strokeWidth="1" fill="none">
                    <g style={animationStyles} id="Desktop" transform="translate(-185.000000, -273.000000)" fill={fill} fillRule="nonzero">
                        <g id="chevron" transform="translate(367.000000, 380.500000) rotate(-270.000000) translate(-367.000000, -380.500000) translate(260.000000, 199.000000)">
                            <path d="M213.667969,181.332031 C213.667969,185.601562 212.386719,189.867188 210.039062,193.066406 L103.375,353.066406 C99.535156,358.828125 93.132812,362.667969 85.667969,362.667969 L21.667969,362.667969 C9.933594,362.667969 0.332031,353.066406 0.332031,341.332031 C0.332031,337.066406 1.613281,332.800781 3.960938,329.601562 L102.734375,181.332031 L3.960938,33.066406 C1.613281,29.867188 0.332031,25.601562 0.332031,21.332031 C0.332031,9.601562 9.933594,5.68434189e-14 21.667969,5.68434189e-14 L85.667969,5.68434189e-14 C93.132812,5.68434189e-14 99.535156,3.839844 103.375,9.601562 L210.039062,169.601562 C212.386719,172.800781 213.667969,177.066406 213.667969,181.332031 Z"/>
                        </g>
                    </g>
                </g>
            </SVG>
        </div>
    )
}

export default Chevron;