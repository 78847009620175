import React, { useState, useEffect } from 'react'
const LangContext = React.createContext()

const Translator = ({ children }) => {
    const [lang, setLang] = useState(navigator.language ? navigator.language.split('-')[0] : 'en')
    const switchLanguage = () => setLang(lang === 'en' ? 'it' : 'en')

    return (
        <LangContext.Provider value={{
            lang,
            switchLanguage
        }}>
            {children}
        </LangContext.Provider>
    )
}

export default Translator
export {
    LangContext
}