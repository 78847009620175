import React, {useEffect, useState, useContext} from 'react';
import styled from 'styled-components/macro'
import Chevron from "./Chevron";
import { Keyframes } from "react-spring/renderprops"
import {useScroll} from "../hooks/custom";
import { TvRatioContext } from '../context/TvRatio';
import {media} from '../styles'

const StyledScrollSigns = styled.div`
position: absolute;
top: 12%;
bottom: 12%;
right: ${props => props.isSafeRatio ? '30%' : '12%'};
display: flex;
width: 1rem;
flex-direction: column;
opacity: .5;
z-index: 10;
filter: blur(1px);

${media.sm`
top: 20%;
bottom: 20%;
right: ${props => props.isSafeRatio ? '30%' : '15%'};
`}
`

const AnimatingChevron = Keyframes.Trail(async next => {
        while (true){
            await next({
                from: {fill : '#000'},
                to: {fill: '#fe0067'},
            })
            await next({
                from: {fill : '#fe0067'},
                to: {fill: '#000'},
            })
        }
    }
)

const TopChevron = styled.div`
position: absolute;
top: 0;
    transform: rotate(180deg);
    opacity: ${props => props.active ? 1 : 0};
    transition: opacity .5s;
`

const BottomChevron = styled.div`
position: absolute;
bottom: 0;
opacity: ${props => props.active ? 1 : 0};
transition: opacity .5s;
`

const ScrollSignsContainer = () => {
    const scroll = useScroll({throttle: 1000})
    const scrollOffset = window.innerHeight / 2
    const bottomOffset = document.body.clientHeight - window.innerHeight - scrollOffset
    const [scrollSignsState, setScrollSignsState] = useState()

    useEffect(() => {
        if (scroll < scrollOffset && scrollSignsState !== 'bottom') {
            setScrollSignsState('bottom')
        } else if ((scroll > scrollOffset && scroll < bottomOffset ) && scrollSignsState !== 'both') {
            setScrollSignsState('both')
        } else if (scroll > bottomOffset && scrollSignsState !== 'top') {
            setScrollSignsState('top')
        }
    }, [scroll])

    return <ScrollSigns status={scrollSignsState}/>
}

const Signs = ({status}) => {
    const chevrons = [Chevron, Chevron, Chevron, Chevron]
    const ratioContext = useContext(TvRatioContext)

    return (
        <StyledScrollSigns isSafeRatio={ratioContext.safeRatio}>
            <TopChevron active={status === 'both' || status=== 'top'}>
                <AnimatingChevron items={chevrons} keys={item => item.key}>
                    {item => anim => <Chevron key={item.key} animationStyles={anim}/>}
                </AnimatingChevron>
            </TopChevron>
            <BottomChevron active={status === 'both' || status === 'bottom'}>
                <AnimatingChevron items={chevrons} keys={item => item.key}>
                    {item => anim => <Chevron key={item.key} animationStyles={anim}/>}
                </AnimatingChevron>
            </BottomChevron>
        </StyledScrollSigns>
    );
};

const ScrollSigns = React.memo(Signs)

export {
    ScrollSignsContainer
}

export default ScrollSigns;
