export default (function () {
    const smallWindow = window.innerWidth < 769
    const mobileUserAgent = (/Android|iPhone|iPad|iPod|BlackBerry/i).test(navigator.userAgent || navigator.vendor || window.opera)
    const retinaCheck = ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)').matches)) || (window.devicePixelRatio && window.devicePixelRatio > 1.3))
    const envCheck = ((/localhost/i).test(window.location) ? 'dev' : 'prod')
  
    const mobileCheck = (smallWindow || mobileUserAgent)
  
    return {
      isMobile: mobileCheck,
      isRetina: retinaCheck,
      env: envCheck,
    }
  })()