export default {
  id: {
    en: "latest-work",
    it: "progetti-recenti"
  },
  title: {
    en: "Latest projects:",
    it: "Progetti più recenti:"
  },
  projectsList: [
    {
      link: "https://lapaelladipiero.com/",
      year: "2020",
      title: {
        en: "La Paella di Piero",
        it: "La Paella di Piero",
      },
      content: {
       en: "Website Design & Development",
       it: "Design e sviluppo del sito ufficiale"
      }
    },
    {
      year: "2019",
      title: {
        en: "Maikii Reserved Area",
        it: "Maikii Area Riservata"
      },
      content: {
        en: "React App - Reserved Area Dashboard & Product Configurator",
        it: "Applicazione React - Bacheca di gestione dell'area riservata e configuratore di prodotti"
      }
    },
    {
      link: "https://northeastcustom.net/",
      year: "2018",
      title: {
        en: "North East Custom",
        it: "North East Custom",
      },
      content: {
        en: "Website Design & Development",
        it: "Design e sviluppo del sito ufficiale"
      }
    },
    {
      link: "https://www.dancenaturals.it/",
      year: "2018",
      title: {
        en: "Dance Naturals",
        it: "Dance Naturals"
      },
      content: {
        en: "Website, Product Configurator & Ecommerce Development (for NoooAgency)",
        it: "Sviluppo sito ufficiale, Configuratore prodotti e portale ecommerce (per NoooAgency)"
      }
    },
    {
      link: "https://www.diemmeattitude.com/",
      year: "2017",
      title: {
        en: "Diemme Attitude",
        it: "Diemme Attitude",
      },
      content: {
        en: "Website Development & courses ecommerce development (for NoooAgency)",
        it: "Sviluppo sito e creazione ecommerce per i corsi Diemme (per NoooAgency)"
      }
    },
    {
      link: "https://www.nidec-industrial.com/",
      year: "2017",
      title: {
        en: "Nidec Industrial",
        it: "Nidec Industrial",
      },
      content: {
        en: "Website Development (for NoooAgency)",
        it: "Sviluppo del sito istituzionale (per NoooAgency)"
      }
    },
    {
      link: "https://www.auxiell.com/",
      year: "2017",
      title: {
        en: "Auxiell",
        it: "Auxiell"
      },
      content: {
        en: "Website Development (for NoooAgency)",
        it: "Sviluppo del sito ufficiale (per NoooAgency)"
      }
    },
    {
      link: "http://www.gruppologo.it/",
      year: "2016",
      title: {
        en: "Gruppo Logo",
        it: "Gruppo Logo"
      },
      content: {
        en: "Website Development (for NoooAgency)",
        it: "Sviluppo del sito ufficiale (per NoooAgency)"
      },
    },
    {
      link: "https://www.valentinasilvestri.net/",
      year: "2016",
      title: {
        en: "Valentina Silvestri",
        it: "Valentina Silvestri"
      },
      content: {
        en: "Website Design & Development",
        it: "Design e sviluppo del sito ufficiale"
      }
    },
    {
      link: "https://www.rubensluciano.com/",
      year: "2016",
      title: {
        en: "Rubens Luciano",
        it: "Rubens Luciano"
      },
      content: {
        en: "Website Development, 3D Motion Graphics Video & Media Production (for NoooAgency)",
        it: "Sviluppo del sito ufficiale, Video in motion graphics 3D e produzione dei contenuti visuali (per NoooAgency)",
      }
    }
  ]
}