import React, { useContext } from 'react';
import { Jumbotron, Title, media, AppBody, AppHeader, AppFooter, colors } from '../styles'
import GlitchedText from "../components/glitched/GlitchedText";
import projects from "../content/projects";
import homeContent from "../content/home"
import Card from "../components/Card";
import GlitchedLink from "../components/glitched/GlitchedLink";
import styled from "styled-components/macro";
import DynamicBackground from "../components/DynamicBackground";
import pages from "../content/pages";
import PageWrapper from "../components/PageWrapper";
import SvgWrapper from "../components/SvgWrapper"
import { Whatsapp, Fb, LinkedIn, Instagram } from '../components/socialIcons'
import ua from '../modules/userAgentHound'
import { LangContext } from '../context/Translator'
import Fade from 'react-reveal/Fade';

const FunnyIntro = styled.p`
font-family: 'Monoton', sans-serif;
margin: 0;
margin-top: -2rem;
font-size: 2rem;
transform: rotate(-5deg) translate(-5%, 2rem);
${media.sm`
margin-top: -15%;
font-size: 3rem;
transform: rotate(-5deg) translate(-10%, 2rem);
text-shadow: #FFF 0px 0px 5px, #FFF 0px 0px 15px, ${props => props.glowColor} 0px 0px 20px, ${props => props.glowColor} 0px 0px 30px, ${props => props.glowColor} 0px 0px 40px, #000 0px 0px 50px, #000 0px 0px 75px;
`}
`

const FadingList = styled.ul`
${!ua.isMobile && `
&:hover,
&:focus,
&:active {
  a {
  opacity: .4;
  }
}

a {
transition: opacity .6s, transform, .3s;
transform-origin: left;

&:hover,
&:focus,
&:active {
  opacity: 1;
  transform: scale(1.1);
}
}
`}
 
  li {
    padding-bottom: ${media.md ? '3rem' : '5rem'};
    ${media.lg`margin: 0 0 3rem 0;`}
}
`

const AboutSection = styled.div`

`

const SocialList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;

  li {
    margin: 0 .5rem;
  }

  a {
border-radius: 50%;
box-shadow: 0 0 10px 0 #fff;
transition: transform .25s;

svg { 
    opacity: .5;
    transition: opacity .25s;
}

    &:hover,
    &:focus {
    transform: scale(1.2);
    color: #000;
    outline: none;
    
    svg {
        opacity: 1;
    }
}

&:active {
    transform: scale(.7);
    
    svg {
        opacity: 1;
    }
}
  }
`

const MobileOnlyGlitchedLink = styled(GlitchedLink)`
${!ua.isMobile ? 'display: none;' : ''}
${media.sm`
display: none;
`}
`

const SkillsList = styled.ul`
strong {
    display: block;
    text-transform: uppercase;
}
`

const SmallContent = styled.div`
p {
 ${media.md`font-size: .9rem;`}
}
`

const SmallText = styled.p`
${media.md`font-size: 80%;`}
`

const Home = () => {
    const { lang } = useContext(LangContext)

    return (
        <>
            <DynamicBackground />
            <PageWrapper page={pages.home}>
                <div style={{ transform: 'translateZ(0)' }}>
                    <AppHeader>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="offset-1 col-9 offset-md-2 col-md-7 text-left">
                                    <FunnyIntro>{homeContent.heading.label[lang]}</FunnyIntro>
                                    <Jumbotron stroked className='position-relative d-inline-block'>
                                        <GlitchedText>{homeContent.heading.title[lang]}</GlitchedText>
                                    </Jumbotron>
                                </div>
                                <div className="offset-1 col-9 offset-md-2 col-md-7 text-left">
                                    <p className='mb-0'>
                                        {homeContent.heading.content[lang]}
                                    </p>
                                    <p>
                                        <GlitchedLink
                                            data-title='hello@manuelpetteno.com'
                                            className="App-link"
                                            href="mailto:hello@manuelpetteno.com"
                                            rel="noopener noreferrer"
                                        >
                                            hello@manuelpetteno.com
                                    </GlitchedLink>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </AppHeader>
                    <AppBody>
                        <AboutSection className="container-fluid mb-5">
                            <div className="row">
                                <div className="offset-1 col-9 offset-md-2 col-md-7 text-left">
                                    <Title stroked as="h3" className="position-relative d-inline-block mt-0">
                                        <GlitchedText>
                                            {homeContent.body.title[lang]}
                                        </GlitchedText>
                                    </Title>
                                    <SmallContent>
                                        {homeContent.body.content[lang]}
                                    </SmallContent>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="offset-1 col-9 offset-md-2 col-md-7 text-left">
                                    <Title stroked as="h3" className="position-relative d-inline-block mt-0">
                                        <GlitchedText>
                                            {homeContent.body.title_skills[lang]}
                                        </GlitchedText>
                                    </Title>
                                </div>
                            </div>
                            <SkillsList className="list-unstyled row">
                                {homeContent.body.skills.map((skill, i) => (
                                    <li key={i} className={i % 2 ? 'offset-1 col-9 offset-md-0 col-md-3 mb-5' : 'offset-1 col-9 offset-md-2 col-md-3 mb-5'}>
                                        <Fade bottom>
                                            <p>
                                                <strong>{skill.title[lang]}</strong>
                                                <SmallText>{skill.content[lang]}</SmallText>
                                            </p>
                                        </Fade>
                                    </li>
                                ))}
                            </SkillsList>
                        </AboutSection>
                        <div id={projects.id[lang]} className="container-fluid">
                            <div className="row">
                                <div className="offset-1 col-9 offset-md-2 col-md-7 text-left">
                                    <Title stroked as="h3" className='position-relative d-inline-block'>
                                        <GlitchedText>
                                            {projects.title[lang]}
                                        </GlitchedText>
                                    </Title>
                                    <FadingList className='list-unstyled row'>
                                        {projects.projectsList.map((project, i) => (

                                            <li
                                                key={project.year + project.title[lang] + i}
                                                className='col-12 col-lg-6'>
                                                {project.link ?
                                                    <a href={project.link} target="_blank" rel="noopener noreferrer">
                                                        <Card
                                                            label={project.year}
                                                            title={project.title[lang]}
                                                            content={project.content[lang]}
                                                        >
                                                            <MobileOnlyGlitchedLink
                                                                data-title={`${lang === 'en' ? 'Check out' : 'Scopri'} ${project.title[lang]}`}
                                                                className="App-link"
                                                                as="small"
                                                            >
                                                                {`${lang === 'en' ? 'Check out' : 'Scopri'} ${project.title[lang]}`}
                                                            </MobileOnlyGlitchedLink>
                                                        </Card>
                                                    </a>
                                                    : <Card
                                                        label={project.year}
                                                        title={project.title[lang]}
                                                        content={project.content[lang]}
                                                    />
                                                }
                                            </li>
                                        ))}
                                    </FadingList>
                                </div>
                            </div>
                        </div>
                    </AppBody>
                    <AppFooter>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="offset-1 col-10 offset-md-2 col-md-7 text-center">
                                    <p>{homeContent.footer.content[lang]}</p>
                                    <SocialList className='list-unstyled'>
                                        <SvgWrapper key='whatsapp' as='li' width='2rem' height='2rem'>
                                            <a href="https://api.whatsapp.com/send?phone=393739037822" target="_blank">
                                                <Whatsapp fill='#fff' />
                                            </a>
                                        </SvgWrapper>
                                        <SvgWrapper key='fb' as='li' width='2rem' height='2rem'>
                                            <a href="https://www.facebook.com/pm.disparos" target="_blank">
                                                <Fb fill='#fff' />
                                            </a>
                                        </SvgWrapper>
                                        <SvgWrapper key='linkedin' as='li' width='2rem' height='2rem'>
                                            <a href="https://www.linkedin.com/in/manuelpetteno/" target="_blank">
                                                <LinkedIn fill='#fff' />
                                            </a>
                                        </SvgWrapper>
                                        <SvgWrapper key='instagram' as='li' width='2rem' height='2rem'>
                                            <a href="https://www.instagram.com/manuelpette" target="_blank">
                                                <Instagram fill='#fff' />
                                            </a>
                                        </SvgWrapper>
                                    </SocialList>
                                </div>
                            </div>
                        </div>
                    </AppFooter>
                </div>
            </PageWrapper>
        </>
    );
};

export default Home;
