import React from 'react'
import jump from 'jump.js'

export default function ({ children, Component, href, offset = 0, speed = 1000, ...rest }) {
    const handleJump = e => {
        e.preventDefault();
        e.stopPropagation(); // Handling nested jumps

        console.log(offset())
        if (href && href.substr(0, 1) === '#') {
            jump(document.querySelector(href), {
                duration: speed,
                offset: typeof offset === 'function' ? offset() : offset, 
            })
        }
    }

    return (
        <Component href={href} {...rest} onClick={handleJump}>
            {children}
        </Component>
    )
}