const pages = {
  home: {
    meta: {
      title: {
        en: "Manuel Pettenò | Freelance Web Developer based in Padova, Italy",
        it: "Manuel Pettenò | Freelance Web Developer a Padova, Italia",
      },
      description: {
        en: "Planning, design and development of websites and modern web applications in Padova",
        it: "Servizi di progettazione, design e sviluppo di siti internet e web application moderne a Padova",
      },
      themeColor: "#fe0067"
    }
  },
  north_east_custom: {
    meta: {
      title: "Manuel Pettenò - Web Developer | North East Custom Project",
      description: "Manuel Pettenò - Web Developer based in Byron Bay",
      themeColor: "#fff"
    }
  }
}

export default pages