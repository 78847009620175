import React, { useContext } from 'react'
import { TvRatioContext } from '../context/TvRatio';
import { LangContext } from '../context/Translator'
import styled from 'styled-components'
import {media} from '../styles'

const Switcher = styled.button`
position: fixed;
top: 50%;
right: ${props => props.isRatioSafe ? '29.5%' : '9.5%'}; // ToDo: Improve this shit
height: 3rem;
width: 3rem;
color: #fff;
font-weight: 700;
line-height: 3rem;
box-shadow: none;
background-color: rgba(255, 255, 255, 0.15);
border: none;
border-radius: 50%;
z-index: 1;
text-transform: uppercase;
box-shadow: 0 0 10px 0 #fff;
transition: background-color .25s, transform .25s, color .25s;

${media.sm`
height: 2rem;
width: 2rem;
line-height: 2rem;
right: ${props => props.isRatioSafe ? '29.5%' : '14.5%'};
`}

&:hover,
&:focus {
    background-color: rgba(255, 255, 255, 1);
    transform: scale(1.2);
    color: #000;
    outline: none;
}

&:active {
    transform: scale(.7);
}
`

const LangSwitcher = ({}) => {
    const {lang, switchLanguage} = useContext(LangContext)
    const ratioContext = useContext(TvRatioContext)
    
    return (
    <Switcher onClick={switchLanguage} isRatioSafe={ratioContext.safeRatio}>
        {lang === 'en' ? 'it' : 'en'}
    </Switcher>
    )
}

export default LangSwitcher