
import styled from 'styled-components/macro'

const TvTestPattern = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => props.isSafeRatio ? '80%' : '100%'};
  height: 100%;
  background-image: url("images/tv-test-pattern.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

export default TvTestPattern;
