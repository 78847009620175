import React, { useContext } from 'react';
import { TvRatioContext } from '../../context/TvRatio'
import styled from 'styled-components/macro'

const Sizer = styled.div`
position: relative;
width: ${props => props.isSafeRatio ? '80%' : '100%'}
`

const TvSizer = ({ children }) => {
    const ratioContext = useContext(TvRatioContext)
    
    return (
        <Sizer isSafeRatio={ratioContext.safeRatio}>
            {children}
        </Sizer>
    )
}

export default TvSizer