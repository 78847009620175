import React, {useEffect, useRef, useState, Fragment, useContext} from 'react'
import styled from 'styled-components/macro'
import {useSpring, a, config as conf} from 'react-spring'
import config from "../appconfig.json"
import {stylesOverlay} from '../styles'
import TvTestPattern from "./tvElements/TvTestPattern";
import {useWindowSizes} from "../hooks/custom"
import GlCanvas from "../modules/GlCanvas";
import GlMouseRippler from "../modules/GlMouseRippler";
import { TvRatioContext } from '../context/TvRatio';

const Scroller = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
`
const FusedCanvas = styled(a.div)`
${stylesOverlay}
background: rgb(215,60,42);
background: linear-gradient(145deg, rgba(215,60,42,1) 0%, rgba(9,46,138,1) 0%, rgba(255,0,0,1) 100%);
`

const FusedOverlay = styled.div`
  ${stylesOverlay}
  opacity .8;
  
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0.98+100 */
background: -moz-radial-gradient(center, ellipse cover,  rgba(0,0,0,0.65) 0%, rgba(0,0,0,0.98) 100%); /* FF3.6-15 */
background: -webkit-radial-gradient(center, ellipse cover,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0.98) 100%); /* Chrome10-25,Safari5.1-6 */
background: radial-gradient(ellipse at center,  rgba(0,0,0,0.65) 0%,rgba(0,0,0,0.98) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#fa000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

&::after {
    content: '';
    ${stylesOverlay}
    background: rgb(215,60,42);
background: linear-gradient(145deg, rgba(215,60,42,1) 0%, rgba(190,3,3,1) 0%, rgba(3,28,91,1) 89%);
}

/* &::after {
    content: "";
    ${stylesOverlay}
    background-image: url(images/80s-grid.jpg);
    background-repeat: no-repeat;
    background-size: ${props => props.isSafeRatio ? '80% 100%' : 'cover'};
    background-position: center;
} */
`

const Button = styled.button`
position: fixed;
bottom: 0;
right: 0;
margin: 1.6rem;
z-index: 9;
`

function useBackgrounds({backgroundsNumber}) {
    const [backgrounds, setBackgrounds]: any = useState([]);
    const [fetching, setFetching] = useState(false)
    const resetBackgrounds: Function = () => setBackgrounds([])

    useEffect(() => {

        if (backgrounds.length || fetching)
            return

        setFetching(true)

        const fetchBackgrounds = () => {
            const vertical = window.innerWidth < window.innerHeight

            // Fetching longside safe images to avoid scaling too much
            const longSide = {
                side: vertical ? 'h' : 'w',
                length: vertical
                    ? Math.max(document.body.clientHeight, window.innerHeight)
                    : window.innerWidth
            }

            fetch(
                `https://api.unsplash.com/photos/random/?client_id=${config.unsplashClientId}&query=80s&count=${backgroundsNumber}`
            )
                .then(response => {
                        try {
                            return response.json()
                        } catch (e){
                            console.error(e)
                        }
                    }
                )
                .catch(alert)
                .then(backgrounds => {
                    if (!backgrounds)
                        return

                    setBackgrounds(backgrounds.map(bg => `${bg.urls.full}&${longSide.side}=${longSide.length}&auto=format&auto=compress`));
                    setFetching(false)
                });
        };

        fetchBackgrounds();

    }, [backgrounds, fetching]);

    return {
        backgrounds,
        resetBackgrounds
    }
}

const Plane = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: ${props => props.isSafeRatio ? '80%' : '100%'};
    height: 100%;
    
    img {
    ${stylesOverlay}
    display: none;
    }
`

const DynamicBackground = ({
                               backgroundsNumber = 2
                           }) => {
    const { backgrounds, resetBackgrounds } = useBackgrounds({backgroundsNumber});
    const [curtainElements, setCurtainElements] = useState({curtain: null, plane: null})
    const canvas = useRef(null);
    const plane = useRef(null);
    const windowSizes = useWindowSizes();
    const [loadedImages, setLoadedImages] = useState([])
    const ratioContext = useContext(TvRatioContext)
    const readyToAnimateIn = loadedImages.length === 2
    const fadeIn = useSpring({config: conf.slow, opacity: readyToAnimateIn ? 1 : 0})

    /*const draw = ({ctx, img, x = 0, y = 0}) => {
        const frameHeight = Math.max(document.body.clientHeight, windowSizes.h)
        const imgW = img.naturalWidth
        const imgH = img.naturalHeight
        const scaled = scaleTo(imgW, imgH, windowSizes.w, frameHeight, 'fill')
        ctx.drawImage(img, x, y, imgW * scaled.scale, imgH * scaled.scale)
    }

    const drawBg = (y: number) => {
        if (!canvas.current)
            return

        const ctx = canvas.current.getContext('2d')
        ctx.globalCompositeOperation = 'difference'
        ctx.clearRect(0, 0, canvas.current.clientWidth, canvas.current.clientHeight)
        loadedImages.map((img, i) => draw({ctx, img, y: y * (i+1)}))
    }*/

    const handleBackgroundsReset: Function = () => {
        curtainElements.curtain.removePlane(curtainElements.plane)
        setLoadedImages([])
        resetBackgrounds()
    }

    useEffect(() => {

        if (!canvas.current)
            return

        const bgElementsList: [HTMLElement] = []
        const updateLoadedImages = e => setLoadedImages(loadedImages => loadedImages.concat(e.target))

        if(backgrounds.length) {
            bgElementsList.concat(backgrounds.map((bg, i) => {
                const img = document.createElement('IMG')
                img.crossOrigin = 'anonymous'
                img.addEventListener('load', updateLoadedImages)
                img.src = backgrounds[i]
                return img
            }))
        }

        return () => bgElementsList.map(img => img.removeEventListener('load', updateLoadedImages))
    }, [backgrounds])

    useEffect(() => {

        if (loadedImages.length === backgroundsNumber) {
            const newCurtain = curtainElements.curtain ? curtainElements.curtain : GlCanvas({
                canvas: canvas.current,
            })

            const mouseRippler = GlMouseRippler({
                controller: newCurtain,
                plane: plane.current,
                mouseElasticity: 10,
                perspective: 50,
                decadence: .98
            })

            mouseRippler.loadImages(loadedImages)

            setCurtainElements({
                curtain: newCurtain,
                plane: mouseRippler
            })
        }

    }, [loadedImages])

    return (
        <Fragment>
            <Scroller>
                <TvTestPattern isSafeRatio={ratioContext.safeRatio}/>
                <FusedCanvas style={fadeIn}  ref={canvas} width={windowSizes.w} height={windowSizes.h}/>
                <Plane ref={plane} data-vs-id='flowing-plane-vs' data-fs-id='flowing-plane-fs' isSafeRatio={ratioContext.safeRatio}/>
                <FusedOverlay />
            </Scroller>
            {/* <Button onClick={handleBackgroundsReset}>Don't like the background? <strong>Change it!</strong></Button> */}
        </Fragment>
    );
};

export default DynamicBackground;
