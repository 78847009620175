import styled, { createGlobalStyle, css } from 'styled-components/macro'
/* import styles from './custom-grid.scss' */


const breakpoints = {
  xxs: 0,
  xs: 350,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
}

const createMediaQuery = ({checkFor, value}) => `(${checkFor}:${value})`

const getCssMediaQuery = (query) => (...args) => css`
    @media ${query} {
      ${css(...args)};
    }`

    const composeMediaQuery = (queries, relation) => (...args) => css`
    @media ${queries.map((query, i) => `${i?' '+relation+' ':''}${createMediaQuery(query)}`)} {
      ${css(...args)};
    }`

const media = Object.keys(breakpoints).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = breakpoints[label] / 16
  accumulator[label] = getCssMediaQuery(createMediaQuery({checkFor: 'min-width', value: `${emSize}em`}))
  return accumulator
}, {})

media['orientation'] = {
  portrait: getCssMediaQuery({checkFor: 'orientation', value: 'portrait'}),
  landscape: getCssMediaQuery({checkFor: 'orientation', value: 'landscape'})
}

const colors = {
  primary: '#fe0067',
  accent: '#55feff',
  yellow: 'yellow'
}


const GlobalStyles = createGlobalStyle`
::selection {
    background-color: #fe0067;
    color: #55feff;
    text-shadow: 0px 0px 8px black;
  }
  
  html {
    font-size: 11px;
    cursor: none !important;
    ${media.sm`font-size: 14px;`}
    ${media.md`font-size: 16px;`}
  }
  

  @font-face {
    font-family: 'lazer84';
    src: url('fonts/lazer84/lazer84.eot');
    src: url('fonts/lazer84/lazer84.eot?#iefix') format('embedded-opentype'),
        url('fonts/lazer84/lazer84.woff2') format('woff2'),
        url('fonts/lazer84/lazer84.woff') format('woff'),
        url('fonts/lazer84/lazer84.ttf') format('truetype'),
        url('fonts/lazer84/lazer84.svg#lazer84') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  
    font-family: 'Roboto Mono', serif;
    font-size: 10px;
    color:#fff;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }
  
  p {
    font-size: 1.1rem;
    // BIG PERFORMANCE ISSUES WITH NEON STYLE
    /* text-shadow: #FFF 0px 0px 5px, #FFF 0px 0px 15px, ${props => props.glowColor} 0px 0px 20px, ${props => props.glowColor} 0px 0px 30px, ${props => props.glowColor} 0px 0px 40px, #000 0px 0px 50px, #000 0px 0px 75px; */
    line-height: 2;
    letter-spacing: -1px;
  }
  
  a {
    font-size: 1.2rem;
    line-height: 2;
    display: inline-block;
    cursor: none !important;
  }
  
  a:hover {
    text-decoration: none;
  }
  
  h1 {
    font-size: 3.2rem;
  }
  
  h2 {
    font-size: 2.8rem;
  }
  
  h3 {
    font-size: 2.4rem;
  }
  
  h4 {
    font-size: 1.8rem;
  }
  
  h1::selection,
  h2::selection,
  h3::selection,
  h4::selection,
  h5::selection,
  h6::selection {
    color: yellow;
  }
  
  h1 span::selection,
  h2 span::selection,
  h3 span::selection,
  h4 span::selection,
  h5 span::selection,
  h6 span::selection {
    color: yellow;
  }
  
  .text-left {
    text-align: left;
  }
  
  .text-center {
    text-align: center;
  }
  
  .text-right {
    text-align: right;
  }
`

const AppBody = styled.div`
  position: relative;
`

const AppHeader = styled.div`
  position: relative;
  display: flex;
  padding: 10vh 0;
  height: auto;
  padding: 20vh 0;
  align-items: center;
  ${media.xs`padding: 15vh 0;`}
  ${media.sm`min-height: 100vh;`}
`
const AppFooter = styled.div`
  position: relative;
  padding-bottom: 20vh;
`

const Title = styled.h2`
    margin-top: .8rem;
    margin-bottom: 2.6rem;
  font-family: 'lazer84', sans-serif;
  font-weight: 700;
  color: #fe0067;
  line-height: 1.4;
  letter-spacing: inherit;
  ${props => props.stroked &&
    `-webkit-text-stroke-width: .5px;
  -webkit-text-stroke-color: #cc0053;`
  }
  
  `

const Jumbotron = styled(Title)`
font-size: 2.2rem;
letter-spacing: 4px; 
${media.orientation.landscape`font-size: 2.2rem;`}
${media.xs`font-size: 3rem;`}
${media.md`font-size: 4rem;`}
`

const stylesOverlay = `position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`

export default GlobalStyles;

export {
  AppBody,
    AppHeader,
    AppFooter,
  Title,
  Jumbotron,
  stylesOverlay,
  media,
  colors
}
