import React from 'react'
import GlitchedLink from '../components/glitched/GlitchedLink'
import JumpLink from '../components/JumpLink'

export default {
    heading: {
        label: {
            en: "Whoa there cowboy!",
            it: "Dove vai cosí di fretta?"
        },
        title: {
            en: 'The Website is under development!',
            it: 'Spiacente il sito è in costruzione!'
        },
        content: {
            en: (
                <>
                While you wait you can check some of my <JumpLink Component={GlitchedLink} offset={() => window.innerHeight * 15 / 100 * -1} data-title='latest work' href="#latest-work">latest work</JumpLink>, play around with this page or
                <strong> hit me up at</strong>:
                </>
                ),
            it: (
                <>
                Nel frattempo puoi vedere alcuni dei miei <JumpLink Component={GlitchedLink}  offset={() => window.innerHeight * 15 / 100 * -1} data-title='progetti più recenti' href="#progetti-recenti">progetti più recenti</JumpLink> qui sotto, giocare un po' con questa pagina o
                <strong> contattarmi su</strong>:
                </>
            )
        }
    },
    body: {
        title: {
            en: 'Something about me first',
            it: 'Chi sono'
        },
        content: {
            en: (
                <>
                <p>I am <strong>Manuel Pettenò</strong> a <strong>Web Developer</strong> based in Padova, Italy.</p>
                    <p className="mt-5">
                    Born in the 80s, I was raised on a diet of bread and computers through the 90s. I fell in love with coding five years ago and I never stopped programming since.
                    </p>
                    
                    <p className="mt-5">I specialise in <strong>front-end development</strong> but I am not afraid to get my hands dirty with <strong>back-end</strong> nerdyness.</p>
                    <p>Let's say I don't stop at the top pancake in the morning...<br></br> I try to go for the <strong>full-stack</strong>.</p>
                </>
            ),
            it: (
                <>
                <p>Mi chiamo <strong>Manuel Pettenò</strong> e sono uno <strong>Sviluppatore Web</strong> con base a Padova.</p>
                    <p className="mt-5">
                    Sono nato alla fine degli anni '80 e sono cresciuto a pane e computer. Mi sono innamorato della programmazione sul web cinque anni fa e da quel momento non ho mai smesso di programmare.
                    </p>
                    
                    <p className="mt-5">Mi occupo principalmente di <strong>design e sviluppo di siti internet e applicazioni web</strong> con una predisposizione per lo <strong>sviluppo front-end</strong> ma non mi spaventa metter le mani in pasta in tecnologie e concetti legati al <strong>back-end</strong>.</p>
                    </>
            )
        },
        title_skills: {
            en: 'Skills in my tool belt',
            it: 'Le mie skill'
        },
        skills: [
            {
                title: {
                    en: 'basis',
                    it: 'basi'
                },
                content: {
                    en: 'Good knowledge of html and responsive web design principles Css, Sass, Less, Javascript, JQuery, Bootstrap',
                    it: 'Buona conoscenza di html e dei principi del responsive web design. Css, Sass, Less, Javascript, JQuery, Bootstrap'
                }
            },
            {
                title: {
                    en: 'Front-end frameworks',
                    it: 'framework front-end'
                },
                content: {
                    en: 'React, Svelte',
                    it: 'React, Svelte'
                }
            },
            {
                title: {
                    en: 'Build and automation tools',
                    it: 'Strumenti per la build e l\'automazione'
                },
                content: {
                    en: 'Webpack, Gulp, GatsbyJs',
                    it: 'Webpack, Gulp, GatsbyJs'
                }
            },
            {
                title: {
                    en: 'Back-end',
                    it: 'Back-end'
                },
                content: {
                    en: 'Php and MySql, basic knowledge of express and mongoose, GraphQL',
                    it: 'Php e MySql, GraphQL, conoscenza di base di express e mongoose'
                }
            },
            {
                title: {
                    en: 'CMS',
                    it: 'CMS'
                },
                content: {
                    en: 'Wordpress and WooCommerce development',
                    it: 'Sviluppo su Wordpress e WooCommerce'
                }
            },
            {
                title: {
                    en: 'VERSION CONTROL AND COMMAND LINE',
                    it: 'Controllo di versione e shell'
                },
                content: {
                    en: 'Git, Unix CLI',
                    it: 'Git, Unix CLI'
                }
            }
        ]
    },
    footer: {
        content: {
            'en' : 'Say hi on whatsapp or find out more about me and my work',
             it: 'Scrivimi su whatsapp o per maggiori info su di me e i miei lavori'
            }
    }
}