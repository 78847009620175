import React, {Fragment} from 'react';
import pages from "../content/pages";
import Head from "./Head";

const PageWrapper = ({page, children}) => {
    return (
        <Fragment>
            <Head page={page}/>
            {children}
        </Fragment>
    );
};

export default PageWrapper;
