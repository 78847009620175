import React, {useContext} from 'react';
import styled, {keyframes} from 'styled-components/macro'
import {ScrollSignsContainer} from "../ScrollSigns";
import { TvRatioContext } from '../../context/TvRatio';
import { media } from '../../styles'
import LangSwitcher from '../../components/LangSwitcher'

const grain = keyframes`{
    0%, 100% { transform:translate3d(0, 0, 0) }
    10% { transform:translate3d(-5%, -10%, 0) }
    20% { transform:translate3d(-15%, 5%, 0) }
    30% { transform:translate3d(7%, -25%, 0) }
    40% { transform:translate3d(-5%, 25%, 0) }
    50% { transform:translate3d(-15%, 10%, 0) }
    60% { transform:translate3d(15%, 0%, 0) }
    70% { transform:translate3d(0%, 15%, 0) }
    80% { transform:translate3d(3%, 35%, 0) }
    90% { transform:translate3d(-10%, 10%, 0) }
}`

const staticLines = keyframes`{
    from {
        transform: translate3d(0, -5%, 0);
    }
    to {
    transform: translate3d(0, 0%, 0);
    }
}`

const syncLine = keyframes`{
    0% {
        transform: translate3d(0, -10vh, 0);
    }
    80%{
        transform: translate3d(0, -10vh, 0);
    }
    100% {
        transform: translate3d(0, 110vh, 0);
    }
}`

const TvStatic = styled.div`
position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateZ(0);
    
   &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    bacgkround-color: red;
    animation: ${grain} 8s steps(10) infinite;
    background-image: url("images/film-noise.png");
    opacity: 0.6;
}

&::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: .15;
    background-image: url("images/scanlines.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    animation: ${staticLines} 1s linear infinite;
}
`;

const TvFrame = styled.div`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('${props => props.tvFrameUrl}');
    background-size: 115% 115%;
    background-position: center;
    background-repeat: no-repeat;
    pointer-events: none;
    z-index: 2;

    ${media.sm`
    background-size: 100% 100%;
    `}
`

const TvSyncLine = styled.div`
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10vh;
    background-color: #000;
    opacity: .5;
    animation: ${syncLine} 5s linear infinite;
`

const TvVignette = styled.div`
    position: absolute;
    top: ${props => props.isRatioSafe ? '10px' : '0'};
    left: ${props => props.isRatioSafe ? '7%' : '3%'};
    width: ${props => props.isRatioSafe ? '68%' : '100%'};
    height: ${props => props.isRatioSafe ? '80%' : '100%'};
    opacity: .6;
    pointer-events: none;
    
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+1,0.45+70,0.9+80 */
background: -moz-radial-gradient(center, ellipse cover,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 1%, rgba(0,0,0,0.45) 70%, rgba(0,0,0,0.9) 80%, rgba(0,0,0,0.9) 100%); /* FF3.6-15 */
background: -webkit-radial-gradient(center, ellipse cover,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 1%,rgba(0,0,0,0.45) 70%,rgba(0,0,0,0.9) 80%,rgba(0,0,0,0.9) 100%); /* Chrome10-25,Safari5.1-6 */
background: radial-gradient(ellipse at center,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 1%,rgba(0,0,0,0.45) 70%,rgba(0,0,0,0.9) 80%,rgba(0,0,0,0.9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#e6000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
`

const NoEvents = styled.div`
position: fixed;
top: 0;
    left: 0;
width: 100%;
    height: 100%;
pointer-events: none;
transform: translateZ(0);
z-index: 9;
`

const StaticTvOverlay = () => {
    const {tvFrameUrl} = useContext(TvRatioContext)

    return (
        <>
        <LangSwitcher/>
        <NoEvents>
            <ScrollSignsContainer/>
            <TvStatic />
            <TvVignette/>
            <TvSyncLine />
            <TvFrame tvFrameUrl={tvFrameUrl}/>
        </NoEvents>
        </>
    )
}

export default StaticTvOverlay;
