import React from 'react';
import styled from 'styled-components/macro'
import {Title} from '../styles'
import Fade from 'react-reveal/Fade';

const CardLabel = styled.span`
    font-size: 1.1rem;
    font-weight: 700;
    opacity: .5;
`

const CardTitle = styled(Title)`
margin: 0;
font-size: 1.4rem;
letter-spacing: 3px;
color: #fff;
`

const CardContent = styled.p`
    font-size: .85rem;
    font-weight: 700;
    letter-spacing: 0;
    text-transform: uppercase;
    opacity: .7;
`

const CardWrapper = styled.span`
display: block;
color: #fff;
font-size: 1.2rem;
line-height: 2;
`

const Card = ({children, label, title, content, ...rest}) => {
    return (
        <Fade bottom cascade>
            <CardWrapper {...rest}>
                <CardLabel>{label}</CardLabel>
                <CardTitle as="h4">{title}</CardTitle>
                <CardContent>{content}</CardContent>
                {children}
            </CardWrapper>
        </Fade>
    )
};

export default Card
