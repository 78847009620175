import {Curtains} from 'curtainsjs';

const GlCanvas = ({canvas}) => {
    const webGLCurtain = new Curtains({
        container: canvas,
        watchScroll: false
    })

    // handling errors
    webGLCurtain.onError(function() {
        // we will add a class to the document body to display original images
        document.body.classList.add("no-curtains")
    });

    return webGLCurtain
}

export default GlCanvas;
