import React, { useEffect, useState, useRef, useContext } from 'react'
import styled, { keyframes } from 'styled-components'
import {Transition} from 'react-spring/renderprops'
import { TvRatioContext } from '../context/TvRatio';
import WebFont from 'webfontloader';

const one = keyframes`
  0%   {opacity:0.2;}
  10%  {opacity:1;}
  20%  {opacity:0.2;}
  100% {opacity:0;}
`
const two = keyframes`
  0%   {opacity:0;}
  10%   {opacity:0.2;}
  20%  {opacity:1;}
  30%  {opacity:0.2;}
  100% {opacity:0;}
`
const three = keyframes`
  0%   {opacity:0;}
  20%  {opacity:0.2;}
  30%  {opacity:1;}
  40%  {opacity:0.2;}
  100% {opacity:0;}
`
const four = keyframes`
  0%   {opacity:0;}
  30%  {opacity:0.2;}
  40%  {opacity:1;}
  50%  {opacity:0.2;}
  100% {opacity:0;}
`
const five = keyframes`
  0%   {opacity:0;}
  40%  {opacity:0.2;}
  50%  {opacity:1;}
  60%  {opacity:0.2;}
  100% {opacity:0;}
`
const six = keyframes`
  0%   {opacity:0;}
  50%  {opacity:0.2;}
  60%  {opacity:1;}
  70%  {opacity:0.2;}
  100% {opacity:0;}
`
const seven = keyframes`
  0%   {opacity:0;}
  60%  {opacity:0.2;}
  70%  {opacity:1;}
  80%  {opacity:0.2;}
  100% {opacity:0;}
`
const eight = keyframes`
  0%   {opacity:0;}
  70%  {opacity:0.2;}
  80%  {opacity:1;}
  90%  {opacity:0.2;}
  100% {opacity:0;}
`
const nine = keyframes`
  0%   {opacity:0;}
  80%  {opacity:0.2;}
  90%  {opacity:1;}
  100% {opacity:0.2;}
`
const ten = keyframes`
  0%   {opacity:0;}
  90%  {opacity:0.2;}
  100%  {opacity:1;}
`

const Led = styled.div`
  width: 1.2rem;
  height: .8rem;
  background: red;
  margin: 1px;
  display: inline-block;
  -webkit-box-shadow: 0 0 30px red, inset 0 0 5px rgba(255,0,0, 0.5);
  border-radius: 2px;
`

const One = styled(Led)`
animation: ${one} 0.7s infinite alternate;
`
const Two = styled(Led)`
animation: ${two} 0.7s infinite alternate;
`
const Three = styled(Led)`
animation: ${three} 0.7s infinite alternate;
`
const Four = styled(Led)`
animation: ${four} 0.7s infinite alternate;
`
const Five = styled(Led)`
animation: ${five} 0.7s infinite alternate;
`
const Six = styled(Led)`
animation: ${six} 0.7s infinite alternate;
`
const Seven = styled(Led)`
animation: ${seven} 0.7s infinite alternate;
`
const Eight = styled(Led)`
animation: ${eight} 0.7s infinite alternate;
`
const Nine = styled(Led)`
animation: ${nine} 0.7s infinite alternate;
`
const Ten = styled(Led)`
animation: ${ten} 0.7s infinite alternate;
`

const Kitt = styled.div`
width: 100%;
text-align: center;
`

const LoaderBg = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #111111;
    z-index: 9;
`

const LoadingIndicatorText = styled.p`
position: relative;
display: inline-block;
span {
position: absolute;
display: inline-block;
right: 0;
transform: translateX(100%);
}
`

const LoadingIndicator = () => {
    const [ellipsis, setEllipsis] = useState('')
    const ellipsisRef = useRef(ellipsis)

    useEffect(() => {
        const interval = window.setInterval(() => {
            if (ellipsisRef.current !== '...') {
                const newEllipsis = ellipsisRef.current + '.'
                ellipsisRef.current = newEllipsis
                setEllipsis(newEllipsis)
            } else {
                ellipsisRef.current = ''
                setEllipsis('')
            }
        }, 1000)

        return () => window.clearInterval(interval)
    }, [])

    return <LoadingIndicatorText>Loading<span>{ellipsis}</span></LoadingIndicatorText>
}

const Loader = ({fontsLoaded}) => {
    const {tvFrameLoaded} = useContext(TvRatioContext)
    const [ready, setReady] = useState(false)

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto Mono', 'Monoton']
            },
            active: () => {
                setReady(true)
            }
        });
    }, [])
    
    return (
    <Transition
    items={!tvFrameLoaded && !fontsLoaded}
    enter={{ opacity: 1 }}
    leave={{ opacity: 0 }}
    >
    {visible => visible && (style => (
        <LoaderBg style={style}>
            <LoadingIndicator />
            <Kitt>
                <One />
                <Two />
                <Three />
                <Four />
                <Five />
                <Six />
                <Seven />
                <Eight />
                <Nine />
                <Ten />
            </Kitt>
        </LoaderBg>))}   
    </Transition>
    )
}

export default Loader