import {lerp} from '../helpers'

const mouse = {
    position: {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
    },
    lastPosition: {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
    },
    delta: 0,
    friction: 30
}


// handle the mouse move event
const handleMovement = (e, plane) => {
    if(mouse.position.x != -100000 && mouse.position.y != -100000) {
        // if mouse position is defined, set mouse last position
        mouse.lastPosition.x = mouse.position.x;
        mouse.lastPosition.y = mouse.position.y;
    }

    // touch event
    if(e.targetTouches) {
        mouse.position = {
            x : e.targetTouches[0].clientX,
            y : e.targetTouches[0].clientY
        }
    }
    // mouse event
    else {
        mouse.position = {
            x : e.clientX,
            y : e.clientY
        }
    }

    // convert our mouse/touch position to coordinates relative to the vertices of the plane
    const mouseCoords = plane.mouseToPlaneCoords(mouse.position.x, mouse.position.y);

    // update our mouse position uniform
    plane.uniforms.mousePosition.value = [mouseCoords.x, mouseCoords.y];

    // calculate the mouse move strength
    if(mouse.lastPosition.x && mouse.lastPosition.y) {
        let delta = Math.sqrt(Math.pow(mouse.position.x - mouse.lastPosition.x, 2) + Math.pow(mouse.position.y - mouse.lastPosition.y, 2)) / mouse.friction;
        delta = Math.min(1, delta);

        // update mouseDelta only if it increased
        if(delta >= mouse.delta) {
            mouse.delta = delta
            // reset our time uniform
            plane.uniforms.time.value = 0;
        }
    }
}

const MouseRippler = ({
                          controller, // WebGlCourtain instance
                          plane,
                          mouseTarget = document.body,
                          mouseElasticity = 30,
                          perspective = 35,
                          decadence = .995,
                      }) => {
    mouse.friction = mouseElasticity

    const pixelRatio = window.devicePixelRatio ? window.devicePixelRatio : 1.0;

    const params = {
        vertexShaderID: "flowing-plane-vs",
        fragmentShaderID: "flowing-plane-fs",
        widthSegments: 20,
        heightSegments: 20,
        uniforms: {
            resolution: { // resolution of our plane
                name: "uResolution",
                type: "2f", // notice this is an length 2 array of floats
                value: [pixelRatio * plane.clientWidth, pixelRatio * plane.clientHeight],
            },
            time: { // time uniform that will be updated at each draw call
                name: "uTime",
                type: "1f",
                value: 0,
            },
            mousePosition: { // our mouse position
                name: "uMousePosition",
                type: "2f", // again an array of floats
                value: [mouse.position.x, mouse.position.y],
            },
            mouseMoveStrength: { // the mouse move strength
                name: "uMouseMoveStrength",
                type: "1f",
                value: 0,
            }
        }
    }

    // create our plane
    const simplePlane = controller.addPlane(plane, params);

    // if there has been an error during init, simplePlane will be null
    simplePlane && simplePlane.onReady(function() {
        // set a fov of 35 to exagerate perspective
        simplePlane.setPerspective(perspective);


        // now that our plane is ready we can listen to mouse move event
        mouseTarget.addEventListener("mousemove", e => handleMovement(e, simplePlane));

        mouseTarget.addEventListener("touchmove", e => handleMovement(e, simplePlane));

        // on resize, update the resolution uniform
        window.addEventListener("resize", () => {
            simplePlane.uniforms.resolution.value = [pixelRatio * plane.clientWidth, pixelRatio * plane.clientHeight];
        });

    }).onRender(function() {
        // increment our time uniform
        simplePlane.uniforms.time.value++;

        // send the new mouse move strength value
        simplePlane.uniforms.mouseMoveStrength.value = mouse.delta;

        // decrease the mouse move strenght a bit : if the user doesn't move the mouse, effect will fade away
        mouse.delta = Math.max(0, mouse.delta * decadence)
    });

    return simplePlane
}

export default MouseRippler