import React from "react";
import styled from "styled-components/macro";
import StaticTvOverlay from "./components/tvElements/StaticTvOverlay";
import MousePointer from "./components/MousePointer";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from './pages/Home'
// import ProjectDisplay from "./components/ProjectDisplay";
import TvRatio from './context/TvRatio'
import Translator from './context/Translator'
import TvSizer from './components/tvElements/TvSizer'
import GlobalStyles from './styles'
import ua from './modules/userAgentHound'
import Loader from './components/Loader'

const AppWrapper = styled.div`
`

const App: React.FC = () => {
    return (
        <>
        <GlobalStyles glowColor='cyan'/>
        <Router>
            <AppWrapper>
            <Translator>
                <TvRatio>
                    <TvSizer>
                        <Switch>
                            <Route>
                                <Home />
                            </Route>
                        </Switch>
                    </TvSizer>
                    <StaticTvOverlay />
                    <Loader/>
                </TvRatio>
                </Translator>
                {!ua.isMobile && <MousePointer />}
            </AppWrapper>
        </Router>
        </>
    );
};

export default App;
