import React, {useContext} from 'react';
import { Helmet } from 'react-helmet';
import { LangContext} from '../context/Translator'

const Head = ({page}) => {
    const {lang} = useContext(LangContext)

    return (
        <Helmet>
            <title>{page.meta.title[lang]}</title>
            <meta name="description" content={page.meta.description[lang]} />
            <meta name="theme-color" content={page.meta.themeColor} />
        </Helmet>
    );
};

export default Head;
