
import styled from 'styled-components/macro'

const SvgWrapper = styled.div`
  width: ${({width}) => width ? width : '5rem'};
  height: ${({height}) => height ? height : '5rem'};

  a {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
      width: 100%;
      height: 100%;
  }
`
export default SvgWrapper