import React, {Fragment, useEffect, useRef} from 'react';
import styled from 'styled-components/macro'
import {shuffle} from "../../helpers";

const prepGlitchData = ({steps}) => {

    let animationsMap : any = []

    for (let i = 0; i < steps; ++i ) {
        const values = [ Math.random() * 100, Math.random() * 100 ]
        const ordered = [...values.sort()]
        const ends = [
            100 - ordered[0],
            100 - ordered[1]
        ]

        const glitchMatrix = [
            [0, ends[0]],
            [ordered[0], ends[1]],
            [ordered[1], 0],
        ]

        const shuffledMatrix = shuffle(glitchMatrix)
        const animationsValues = shuffledMatrix.map(([top, bottom]) => `inset(${top}% 0 ${bottom}% 0)`)
        animationsMap.push(animationsValues)
    }
    
    return animationsMap
}

const WhiteChannel = styled.span`
display: inline-block;
position: relative;
padding-left: 10px;
color: inherit;
z-index: 1;
-webkit-text-stroke-width: inherit;
  -webkit-text-stroke-color: inherit;
`

const CyanChannel = styled.span`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
padding-left: 10px;
color: inherit;
  left: -5px;
  text-shadow: -1px 0 #55feff;
`

const RedChannel = styled.span`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
padding-left: 10px;
color: inherit;
 left: 5px;
  text-shadow: -1px 0 #fe0067;
    `

const GlitchedText = ({
                               fps = 10,
                               colors = ['#fe0067', '#55feff', 'yellow'],
                               children,
                                strength = 2
                           }) => {

    const whiteLayer = useRef()
    const redLayer = useRef()
    const cyanLayer = useRef()
    const i = useRef(0)
    const t = useRef(0)

    useEffect(() => {
        
        const animation = {steps: 10}
        const animationsMap = prepGlitchData({steps: animation.steps})

        const render = () => {

            if (t.current > (60 / fps)) { // Fps
                t.current = 0
                
                const layers = [whiteLayer.current, redLayer.current, cyanLayer.current]
                
                layers.map((layer, k) => {
                    layer.style.clipPath = animationsMap[i.current][k]
                    layer.style.webkitClipPath = animationsMap[i.current][k]

                    if (k === 1) {
                        layer.style.textShadow = `-${ Math.random() * 2 * strength}px ${Math.random() * 2 * strength}px ${colors[Math.floor(Math.random() * 3 * strength)]}`
                    }

                    if(k === 2) {
                        layer.style.textShadow = `-${ Math.random() * 2 * strength }px ${Math.random() * 2 * strength}px ${colors[Math.floor(Math.random() * 3 * strength)]}`
                    }

                    return
                })

                i.current + 1 < animation.steps ? ++i.current : i.current = 0
            }

            ++t.current
            window.requestAnimationFrame(render)
        }

        if(whiteLayer.current && redLayer.current && cyanLayer.current ){
        window.requestAnimationFrame(render)
    }

    }, []);

    return (
        <Fragment>
            <WhiteChannel ref={whiteLayer}>{children}</WhiteChannel>
            <RedChannel ref={redLayer}>{children}</RedChannel>
            <CyanChannel ref={cyanLayer}>{children}</CyanChannel>
        </Fragment>
    )
}

export default GlitchedText;
